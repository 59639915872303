<template>
  <div class="col-12">
    <b-modal
      size="xl"
      id="ModalManageBuyToGether"
      hide-footer
      hide-header
      scrollable
      no-close-on-backdrop
    >
      <p class="px-3 font-600 w-100" style="display: flex">
        {{ datainput.mode }} Group Bought Together &nbsp;
        <span style="flex: 1; text-align: right; white-space: nowrap;"
          ><button class="btn btn-sm " @click="cancel()">
            x
          </button></span
        >
      </p>

      <div class="card p-3 m-2">
        <div
          class="row form-inline"
          v-if="datainput.mode == 'Edit' ? true : false"
        >
          <div class="col-12 col-lg-2 mb-4 text-right">รหัสกลุ่มสินค้า</div>
          <div class="col-12 col-lg-10 mb-4">
            <div class="row">
              <div class="col-6">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">หมายเลข</span>
                  </div>
                  <input
                    :disabled="datainput.mode == 'Edit' ? true : false"
                    type="number"
                    class="form-control"
                    placeholder="รหัสกลุ่มสินค้า"
                    max="8"
                    v-model="items.GroupId"
                  />            
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-inline">
          <div class="col-12 col-lg-2 mb-4 text-right">*ชื่อกลุ่มสินค้า TH</div>
          <div class="col-12 col-lg-10 mb-4">
            <div class="row">
              <div class="col-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">TH</span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ชื่อกลุ่มสินค้า TH"
                    maxlength="100"
                    v-model="items.Name_TH"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-inline">
          <div class="col-12 col-lg-2 mb-4 text-right">*ชื่อกลุ่มสินค้า EN</div>
          <div class="col-12 col-lg-10 mb-4">
            <div class="row">
              <div class="col-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">EN</span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ชื่อกลุ่มสินค้า EN"
                    maxlength="100"
                    v-model="items.Name_EN"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-inline">
          <div class="col-12 col-lg-2 mb-4 text-right">หมายเหตุ</div>
          <div class="col-12 col-lg-10 mb-4">
            <div class="row">
              <div class="col-12">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="หมายเหตุ..."
                    maxlength="40"
                    v-model="items.Remark"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-inline">
          <div class="col-12 col-lg-2 mb-4 text-right">*รูปกลุ่มสินค้า</div>
          <div class="col-12 col-lg-10 mb-4">
            <div class="row">
              <div class="col-12">
                <div class="input-group">
                  <!-- <div class="image-zoom"> -->
                  <img
                    v-if="ImageUrl"
                    :src="ImageUrl"
                    width="200px"
                    height="200px"
                    alt="Image"
                    class="img-thumbnail "
                  />
                  <img
                    v-else-if="
                      items.IMGBase64 != 'null' && items.IMGBase64 != null
                    "
                    :src="`data:image/jpeg;base64,${items.IMGBase64}`"
                    width="200px"
                    height="200px"
                    alt="Image"
                    class="img-thumbnail"
                  />
                  <!-- </div> -->
                </div>
              </div>
              <div class="col-12">
                <input type="file" @change="handleFileUpload" />
              </div>
            </div>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-12 text-right">
            <button class="btn bt-main px-5 mr-1" @click="save()">
              บันทึก
            </button>
            <button class="btn btn-light px-5" @click="cancel()">
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
      <div
        class="row m-0 px-0 justify-content-center"
        v-if="datainput.mode != 'Add'"
      >
        <div class="col-12 col-lg-12 m-0 p-0 shadow mb-3 bg-white rounded">
          <div class="row m-0 py-4 px-2">
            <p class="px-3 font-600 w-100" style="display: flex">
              สินค้า&nbsp;
              <span class="text-main">{{
                new Intl.NumberFormat().format(totalRows)
              }}</span>
              &nbsp;รายการ
            </p>
            <div class="col-12">
              <b-table
                :empty-text="'ไม่พบข้อมูล / No Data'"
                :fields="fields"
                :items="itemsList"
                :current-page="page_num"
                :per-page="page_size"
                hover
                outlined
                show-empty
                responsive
                :busy="loading"
                head-variant="light"
                class="font-0-8s"
              >
                <template #table-busy>
                  <div class="text-center text-main my-2">
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #cell(Error)="row">
                  <div>
                    <strong
                      class="text-center text-twd my-2"
                      v-if="row.item.Error == '1'"
                      >ไม่พบข้อมูลสินค้า</strong
                    >
                    <strong v-else>-</strong>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="col-12">
              <div class="row justify-content-end">
                <div class=" col-xl-3"></div>
                <div class=" col-xl-3 input-group-prepend input-group-md">
                  <select class="custom-select" v-model="page_size">
                    <option
                      v-for="(e, i) in page_size_list"
                      :key="i"
                      :value="e"
                    >
                      {{ e }}
                    </option>
                  </select>
                </div>
                <div class=" col-xl-3">
                  <b-pagination
                    v-model="page_num"
                    :total-rows="totalRows"
                    :per-page="page_size"
                    align="fill"
                    size="md"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ModalManageBuyToGether",
  props: ["datainput"],
  data() {
    return {
      ImageUrl: null,
      ImageBase64: null,
      page_size: 10,
      loading: false,
      totalRows: 0,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "GroupId", label: "GroupId", class: "text-left" },
        { key: "Skcode", label: "Skcode", class: "text-left" },
        { key: "prNameTH", label: "prNameTH", class: "text-left" },
        { key: "Error", label: "Error", class: "text-center" },
        { key: "Last_update", label: "Last_update", class: "text-center" },
        { key: "CreatedBy", label: "CreatedBy", class: "text-left" },
        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
      itemsList: null,
      items: {
        GroupId: "",
        Name_TH: "",
        Name_EN: "",
        Remark: "",
        IMGBase64: null,
        Active: "",
      },
    };
  },
  async created() {
    await this.getdataList();
  },
  methods: {
    async cancel() {
      this.ImageUrl = null;
      this.items = {
        GroupId: "",
        Name_TH: "",
        Name_EN: "",
        Remark: "",
        IMGBase64: null,
        Active: "",
      };
      await this.$bvModal.hide("ModalManageBuyToGether");
    },
    async save() {
      try {
        await this.validate();
        if (this.ImageBase64 != null) {
          this.items.IMGBase64 = this.ImageBase64.img;
        }
        let data = { mode: "Group", datas: this.items };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `manage/updateProductBoughtTogether`,
          data,
          1
        );
        if (getAPI.data[0].MSG == "OK") {
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        }
        await this.cancel() 
        await this.$store.dispatch("boughtTogetherActions/getGroupData");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async validate() {
      if (this.items.Name_TH === "" || this.items.Name_EN === "") {
        throw `กรุณากรอกชื่อกลุ่มสินค้า`;
      }
    },
    async handleFileUpload(event) {
      try {
        const file = event.target.files[0];

        if (file) {
          this.ImageUrl = URL.createObjectURL(file);
          if (
            file.type !== "image/jpeg" &&
            file.type !== "image/png" &&
            file.type !== "image/jpg"
          ) {
            throw `Support .png, .jpg, .jpeg only`;
          }
          this.ImageUrl = URL.createObjectURL(file);
          this.ImageBase64 = await this.convert_imgbase64(file);
        } else {
          this.ImageUrl = null;
          this.ImageBase64 = null;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getdataList() {
      if (this.datainput.mode == "Edit") {
        const {
          GroupId,
          Name_TH,
          Name_EN,
          Remark,
          IMGBase64,
          Skcode,
          Active,
        } = this.datainput.data[0];
        this.items = {
          GroupId: GroupId,
          Name_TH: Name_TH,
          Name_EN: Name_EN,
          Remark:Remark,
          IMGBase64: IMGBase64,
          Active: Active,
        };
        if (Skcode !== null) {
          this.itemsList = this.datainput.data;
          this.totalRows = this.datainput.data.length;
        }
      } else {
        this.items = {
          GroupId: "",
          Name_TH: "",
          Name_EN: "",
          Remark:"",
          IMGBase64: null,
          Active: "",
        };
      }
    },
    async RemoveProductBuyToGether(GroupId, FreqSkcode) {
      try {
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการลบข้อมูลสินค้า ${FreqSkcode} หรือไม่? / Do you want to delete data?`
        );
        if (confirm) {
          let data = { Mode: "D", GroupId: GroupId, FreqSkcode: FreqSkcode };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `manage/RemoveProductBoughtTogether`,
            data,
            1
          );
          if (getAPI.data[0].MSG == "OK") {
            this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
            this.$emit("CallModalManageBuyToGether", GroupId);
          }
          // this.$emit("CallModalManageBuyToGether", Skcode);
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    convert_imgbase64(file) {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var image = new Image();
          image.src = reader.result;
          image.onload = function() {
            resolve({ img: reader.result, size: { h: 400, w: 400 } });
          };
        };
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>

