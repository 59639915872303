<template>
  <div class="col-12">
    <b-modal
      size="xl"
      id="ModalProductBuyToGether"
      hide-footer
      hide-header
      scrollable
      no-close-on-backdrop
    >
      <p class="px-3 font-600 w-100" style="display: flex">
        {{ datainput.mode }} Product Bought Together &nbsp;
        <span style="flex: 1; text-align: right; white-space: nowrap;"
          ><button class="btn btn-sm " @click="cancel()">
            x
          </button></span
        >
      </p>
      <div class="card p-3 m-2">
        <div class="row w-100">
          <div class="col-12 col-lg-2 mb-3 text-right mt-1">กลุ่มสินค้า</div>
          <div class="col-12 col-lg-10 mb-3">
            <div class="row">
              <div class="col-10">
                <div class="input-group">
                  <multiselect
                    v-model="GroupSelected"
                    :options="GroupList"
                  ></multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2 mb-3 text-right mt-1">รหัสสินค้า</div>
          <div class="col-12 col-lg-10 mb-3">
            <div class="row">
              <div class="col-10">
                <div class="input-group">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    placeholder="ชื่อกลุ่มสินค้า TH"
                    v-model="items.Skcode"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2 mb-3 text-right mt-1">ชื่อสินค้า TH</div>
          <div class="col-12 col-lg-10 mb-3">
            <div class="row">
              <div class="col-10">
                <div class="input-group">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    placeholder="ชื่อกลุ่มสินค้า TH"
                    v-model="items.prNameTH"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2 mb-3 text-right mt-1">ชื่อสินค้า EN</div>
          <div class="col-12 col-lg-10 mb-3">
            <div class="row">
              <div class="col-10">
                <div class="input-group">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    placeholder="ชื่อกลุ่มสินค้า TH"
                    v-model="items.prNameEN"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-right mt-3">
            <button class="btn bt-main px-5 mr-1" @click="save()">
              บันทึก
            </button>
            <button class="btn btn-light px-5" @click="cancel()">
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-12 m-0 p-0 shadow mb-3 bg-white rounded">
        <div class="row m-0 py-4 px-2">
          <div class="px-3 font-700 w-100" style="display: flex">
            <div v-if="selected.length > 0">
              เลือก &nbsp;
              <span class="text-twd">{{
                new Intl.NumberFormat().format(selected.length)
              }}</span>
              &nbsp;รายการ
            </div>
            <div v-else>
              สินค้า &nbsp;
              <span class="text-main">{{
                new Intl.NumberFormat().format(totalRows)
              }}</span>
              &nbsp;รายการ
            </div>
            <span
              style="flex: 1; text-align: right; white-space: nowrap;"
              v-if="selected.length > 0"
              ><button class="btn btn-md bt mb-2" @click="Delete()">
                Delete
              </button></span
            >
          </div>
          <div class="col-12">
            <b-table
              outlined
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="itemsList"
              :current-page="page_num"
              :per-page="page_size"
              hover
              show-empty
              responsive
              :busy="loading"
              class="font-0-8s"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
              @row-clicked="onRowSelected"
              ref="selectableTable"
              head-variant="light"
            >
              <template #table-busy>
                <div class="text-center text-main my-2">
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #head(selected)="">
                <div class="form-check">
                  <input
                    class="form-check-input position-static"
                    type="checkbox"
                    v-model="selectedAll"
                    @click="selectAllRows"
                  />
                </div>
              </template>
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <b-icon icon="check-square-fill" variant="secondary"></b-icon>
                </template>
                <template v-else>
                  <b-icon icon="square" variant="secondary"></b-icon>
                </template>
              </template>
              <template #cell(Error)="row">
                <div>
                  <strong
                    class="text-center text-twd my-2"
                    v-if="row.item.Error == '1'"
                    >ไม่พบข้อมูลสินค้า</strong
                  >
                  <strong v-else>-</strong>
                </div>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class=" col-xl-3"></div>
              <div class=" col-xl-3 input-group-prepend input-group-md">
                <select class="custom-select" v-model="page_size">
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class=" col-xl-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="md"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ModalProductBuyToGether",
  props: ["datainput"],
  data() {
    return {
      selectMode: "multi",
      selected: [],
      selectedAll: false,
      GroupSelected: null,
      GroupList: null,
      ImageUrl: null,
      ImageBase64: null,
      page_size: 10,
      loading: false,
      totalRows: 0,
      page_size_list: [10, 20, 30, 40, 50],
      page_num: 1,
      fields: [
        {
          key: "selected",
          label: "",
          thClass: "bg-twd text-white",
          class: "text-center",
        },
        { key: "Skcode", label: "Skcode", class: "text-left"  },
        { key: "FreqSkcode", label: "FreqSkcode", class: "text-left" },
        { key: "FreqNameTH", label: "FreqNameTH", class: "text-left" },
        { key: "FreqNameEN", label: "FreqNameEN", class: "text-left" },
        { key: "Error", label: "Error", class: "text-center" },
        { key: "LastUpdate", label: "LastUpdate", class: "text-center" },
        { key: "UpdateBy", label: "UpdateBy", class: "text-left" },
        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
      itemsList: null,
      items: {
        Id: "",
        GroupId: "",
        Skcode: "",
        prNameTH: "",
        prNameEN: "",
      },
    };
  },
  watch: {
    currentPage() {
      this.selectedAll = false;
    },
    perPage() {
      this.selectedAll = false;
    },
  },
  async created() {
    await this.getdataList();
    await this.getGroupData();
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      if (!this.selectedAll) {
        this.$refs.selectableTable.selectAllRows();
      } else {
        this.$refs.selectableTable.clearSelected();
      }
    },
    async getGroupData() {
      try {
        let getAPI = await this.$store.getters[
          "boughtTogetherActions/GET_Group_All"
        ];
        const GroupList = ["0 : ไม่พบกลุ่มสินค้า No Group"];
        if (getAPI !== null) {
          for (var i in getAPI) {
            GroupList.push(String(getAPI[i].GroupId).trim() + " : " + String(getAPI[i].Name_TH).trim()+ " " + String(getAPI[i].Name_EN).trim());
          }
          this.GroupList = GroupList;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async cancel() {
      this.items = {
        Id: "",
        GroupId: "",
        Skcode: "",
        prNameTH: "",
        prNameEN: "",
      };
      await this.$bvModal.hide("ModalProductBuyToGether");
    },
    async save() {
      try {
        if (this.selected.length == 0) {
          throw `กรุณาเลือก FreqSkucode `;
        }
        var SkcodeList = [];
        for (var row in this.selected) {
          SkcodeList.push(this.selected[row].Id);
        }
        this.items.Id = String(SkcodeList);
        this.items.GroupId = String(this.GroupSelected).split(":")[0];
        let data = { datas: this.items };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `manage/updateGroupByIdBoughtTogether`,
          data,
          1
        );
        if (getAPI.data[0].MSG == "OK") {
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        }
        await this.$bvModal.hide("ModalProductBuyToGether");
        await this.$store.dispatch("boughtTogetherActions/getProductData");
        await this.$store.dispatch("boughtTogetherActions/getGroupData");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getdataList() {
      const {
        GroupId,
        GroupNameTH,
        GroupNameEN,
        Skcode,
        prNameTH,
        prNameEN,
      } = this.datainput.items;
      this.items = {
        GroupId: GroupId,
        Skcode: Skcode,
        prNameTH: prNameTH,
        prNameEN: prNameEN,
      };
      if (GroupId == 0) {
        this.GroupSelected = "0 : ไม่พบกลุ่มสินค้า No Group";
      } else {
        this.GroupSelected =
          String(GroupId).trim() + " : " + String(GroupNameTH).trim() +" "+ String(GroupNameEN).trim();
      }
      if (this.datainput.data[0].FreqSkcode !== null) {
        this.itemsList = this.datainput.data;
        this.totalRows = this.datainput.data.length;
      }
    },
    async Delete() {
      try {
        var FreqSkcode = [];
        var FreqSkcodes = [];
        for (var row in this.selected) {
          FreqSkcode.push(this.selected[row].FreqSkcode);
          FreqSkcodes.push(this.selected[row].Id);
        }
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการลบ FreqSkcode ${FreqSkcode} หรือไม่? / Do you want to delete data?`
        );
        if (confirm) {
          let data = {
            Mode: "FreqSkcode",
            GroupId: "",
            Skcode: "",
            FreqSkcode: FreqSkcodes,
          };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `manage/RemoveProductBoughtTogether`,
            data,
            1
          );
          if (getAPI.data[0].MSG == "OK") {
            this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          }
          await this.$bvModal.hide("ModalProductBuyToGether");
          await this.$store.dispatch("boughtTogetherActions/getProductData");
          await this.$store.dispatch("boughtTogetherActions/getGroupData");
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>
