<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Bought Together</p>
          </div>
        </div>
        <div class="row m-0 p-0 w-100">
          <div class="col-12">
            <b-tabs v-model="tabIndex" content-class="mt-1 p-3">
              <b-tab
                title="Create Group"
                :title-link-class="linkClass(0)"
              >
                <TableGroupBoughtTogether />
              </b-tab>
              <b-tab title=" Add Bought Together" :title-link-class="linkClass(1)">
                <UploadProductBoughtTogether />
              </b-tab>
              <b-tab
                title="Bought Together List"
                :title-link-class="linkClass(2)"
              >
                <TableProductBoughtTogether />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableGroupBoughtTogether from "../../components/Manage/BoughtTogether/TableGroupBoughtTogether.vue";
import TableProductBoughtTogether from "../../components/Manage/BoughtTogether/TableProductBoughtTogether.vue";
import UploadProductBoughtTogether from "../../components/Manage/BoughtTogether/UploadProductBoughtTogether.vue";


export default {
  name: "BoughtTogether",
  components: {
    TableProductBoughtTogether,
    UploadProductBoughtTogether,
    TableGroupBoughtTogether,
  },
  data() {
    return {
      tabIndex: 0,
      errorCount: 0,
    };
  },
  async mounted() {
    await this.$store.dispatch("boughtTogetherActions/getGroupData");
    await this.$store.dispatch("boughtTogetherActions/getProductData");
    // this.errorCount = this.$store.getters[
    //   "boughtTogetherActions/GET_Data_Error"
    // ].length;
  },
  computed: {
    GET_Group_All: function() {
      return this.$store.getters["boughtTogetherActions/GET_Group_All"];
    },
    GET_Product_All: function() {
      return this.$store.getters["boughtTogetherActions/GET_Product_All"];
    },
  },
  watch: {
    GET_Group_All: function(v) {
      this.getGroupData();
    },
    GET_Product_All: function(v) {
      this.getProductData();
    },
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-main", "font-weight-bold"];
      } else {
        return ["text-muted"];
      }
    },
    async getGroupData() {
      try {
        await this.$store.dispatch["boughtTogetherActions/getGroupData"];
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getProductData() {
      try {
        await this.$store.dispatch["boughtTogetherActions/getProductData"];
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style scoped></style>
