<template>
  <div id="productNoLanguage" class="container-fluid m-0 p-0">
    <div class="col-12 col-lg-12 m-0 p-0 ">
      <div class="row mx-0 px-0">
        <div class="col-12 col-xl-6 mb-3 mt-3">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend input-group-sm">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>
            <input
              type="search"
              class="form-control form-control-sm"
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn bt-main" @click="Search()">ค้นหา</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 px-0 justify-content-center">
      <div class="col-12 col-lg-12 m-0 p-0 ">
        <div class="row m-0 py-4 px-2 ">
          <p class="px-3 font-700 w-100 mb-0" style="display: flex">
            จำนวน &nbsp;
            <span class="text-main m-0">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            <span style="flex: 1; text-align: right; white-space: nowrap;">
              <button
                class="btn btn-md bt-bnb ml-2 mb-1"
                @click="DownloadAllData()"
              >
                นำออกข้อมูล
              </button></span
            >
          </p>
          <div class="col-12">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              :current-page="page_num"
              :per-page="page_size"
              hover
              outlined
              show-empty
              responsive
              :busy="loading"
              class="font-0-8s"
              striped
            >
              <template #table-busy>
                <div class="text-center text-main my-2">
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(Error)="row">
                <div>
                  <strong
                    class="text-center text-twd my-2"
                    v-if="row.item.Error == '1'"
                    >ไม่พบข้อมูลสินค้า</strong
                  >
                  <strong v-else>-</strong>
                </div>
              </template>
              <template #cell(GroupNameTH)="row">
                <div>
                  <strong
                    class="text-center text-twd my-2"
                    v-if="row.item.GroupId == 0"
                    >ไม่พบกลุ่มสินค้า</strong
                  >
                  <strong v-else>{{ row.item.GroupNameTH }}</strong>
                </div>
              </template>
              <template #cell(GroupNameEN)="row">
                <div>
                  <strong
                    class="text-center text-twd my-2"
                    v-if="row.item.GroupId == 0"
                    >No Group</strong
                  >
                  <strong v-else>{{ row.item.GroupNameEN }}</strong>
                </div>
              </template>
              <template #cell(action)="row">
                <div class="row col-12 ml-4 ">
                  <span
                    v-html="$svg_icon.edit"
                    type="button"
                    class="text-muted mr-3"
                    @click="CallModalManageBuyToGether(row.item)"
                  ></span>
                  <span
                    v-html="$svg_icon.remove"
                    type="button"
                    class="text-twd"
                    @click="Delete(row.item)"
                  ></span>
                </div>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-3 input-group-prepend input-group-sm">
                <select class="custom-select" v-model="page_size">
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="md"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalProductBuyToGether
      :key="reModal"
      :datainput="dataModal"
      @CallModalManageBuyToGether="CallModalManageBuyToGether"
    />
  </div>
</template>
<script>
import ModalProductBuyToGether from "./ModalProductBuyToGether.vue";
export default {
  name: "TableBoughtTogetherList",
  components: { ModalProductBuyToGether },
  data() {
    return {
      reModal: Date.now() + 151,
      onOff: false,
      items: null,
      itemlist: null,
      totalRows: 0,
      find: "",
      page_size: 10,
      loading: false,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      mode: "Skcode",
      dataModal: { mode: "", items: null, data: null },
      select_mode: [
        { name: "ค้นหาด้วย Skcode", key: "Skcode" },
        { name: "ค้นหาด้วย NameTH", key: "prNameTH" },
        { name: "ค้นหาด้วย NameEN", key: "prNameEN" },
        { name: "ค้นหาด้วย GroupNameTH", key: "GroupNameTH" },
        { name: "ค้นหาด้วย GroupNameEN", key: "GroupNameEN" },
      ],
      fields: [
        {
          key: "GroupNameTH",
          label: "GroupNameTH",
          class: "text-left",
          sortable: true,
        },
        {
          key: "GroupNameEN",
          label: "GroupNameEN",
          class: "text-left",
          sortable: true,
        },
        {
          key: "Skcode",
          label: "MainSkcode",
          class: "text-center",
          sortable: true,
        },
        {
          key: "prNameTH",
          label: "NameTH",
          class: "text-left",
          sortable: true,
        },
        {
          key: "prNameEN",
          label: "NameEN",
          class: "text-left",
          sortable: true,
        },
        { key: "Qty", label: "FreqQty", class: "text-right", sortable: true },
        { key: "Error", label: "Error", class: "text-center", sortable: true },
        {
          key: "LastUpdate",
          label: "Last Update",
          class: "text-left",
          sortable: true,
        },
        {
          key: "UpdateBy",
          label: "UpdateBy",
          class: "text-left",
          sortable: true,
        },
        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
    GET_Product_All: function(v) {
      this.getdata();
    },
  },
  computed: {
    GET_Product_All: function() {
      return this.$store.getters["boughtTogetherActions/GET_Product_All"];
    },
  },
  mounted() {
    this.getdata();
  },
  methods: {
    async DownloadAllData() {
      try {
        let data = { mode: "Skcode", GroupId: "", Skcode: "" };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `manage/getboughttogethelist`,
          data,
          1
        );
        if (getAPI.data.length > 0) {
          const datas = [];
          for (var i in getAPI.data) {
            let SkcodeAll = {
              GroupId: getAPI.data[i].GroupId,
              GroupNameTH: getAPI.data[i].GroupNameTH,
              GroupNameEN: getAPI.data[i].GroupNameEN,
              Skcode: getAPI.data[i].Skcode,
            };
            datas.push(SkcodeAll);
          }
          this.$serviceMain.JsonToExcat(datas, "AllSkcode_BoughtTogether.xlsx");
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getdata() {
      try {
        let getAPI = await this.$store.getters[
          "boughtTogetherActions/GET_Product_All"
        ];

        if (getAPI !== null) {
          this.items = getAPI;
          this.itemlist = getAPI;
          this.totalRows = getAPI.length;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        this.getdata();
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
          // }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    async CallModalManageBuyToGether(item) {
      try {
        let data = {
          mode: "FreqSkcode",
          GroupId: item.GroupId,
          Skcode: item.Skcode,
          FreqSkcode: "",
        };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `manage/getboughttogethelist`,
          data,
          1
        );

        this.reModal = Date.now();
        this.dataModal.mode = "Edit";
        this.dataModal.items = item;
        this.dataModal.data = getAPI.data;
        setTimeout(() => {
          this.$bvModal.show("ModalProductBuyToGether");
        }, 200);
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async Delete(Item) {
      try {
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการลบ Skcode ${Item.Skcode} หรือไม่? / Do you want to delete data?`
        );
        if (confirm) {
          let data = {
            Mode: "Skcode",
            GroupId: Item.GroupId,
            Skcode: Item.Skcode,
            FreqSkcode: "",
          };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `manage/RemoveProductBoughtTogether`,
            data,
            1
          );
          if (getAPI.data[0].MSG == "OK") {
            this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          }
          await this.$store.dispatch("boughtTogetherActions/getProductData");
          await this.$store.dispatch("boughtTogetherActions/getGroupData");
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>
